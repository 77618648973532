import { Injectable } from '@angular/core';
import { RemoteGatewayFactory } from '../gateways/remote-gateway-factory';
import { MailVoucherDTO } from '../models/domains/usuario-voucher/mail-voucher.dto';
import { PlaceholderImageDTO } from '../models/domains/usuario-voucher/placeholder-image.dto';
import { VoucherNotificationDTO } from '../models/domains/usuario-voucher/voucher-notification.dto';
import { ErrorParser } from './parsers/error.parser';


@Injectable()
export class VoucherGeneratorRepository {

  constructor(
    private remoteGatewayFactory: RemoteGatewayFactory,
    private errorParser: ErrorParser) { }

  public async generateVouchers(vouchersConfig: VoucherNotificationDTO, campaignBanner: File, usersSheet: File, vouchersSheet: File): Promise<void> {
    try {
      const formData = new FormData();
      formData.append('VoucherNotificationDTO', JSON.stringify(vouchersConfig));
      formData.append('ExcelUsers', usersSheet);
      formData.append('ImageNotification', campaignBanner);
      formData.append('ExcelVoucherNumber', vouchersSheet);

      const remoteGateway = this.remoteGatewayFactory.createDefaultRemoteGateway();
      await remoteGateway.post('/api/generateVouchers', formData);
    }
    catch (error) {
      return await Promise.reject(this.errorParser.parse(error));
    }
  }

  public async previewVouchers(mailConfig: MailVoucherDTO, placeholders: PlaceholderImageDTO[], campaignBanner: File): Promise<any> {
    try {
      const formData = new FormData();
      formData.append('EmailsTest', JSON.stringify(mailConfig));
      formData.append('PlaceholderTest', JSON.stringify(placeholders));
      formData.append('ImageNotification', campaignBanner);

      const remoteGateway = this.remoteGatewayFactory.createDefaultRemoteGateway();
      await remoteGateway.post('/api/generateVouchers/pre', formData);
    }
    catch (error) {
      return await Promise.reject(this.errorParser.parse(error));
    }
  }

  public async downloadLastExecutedReport(): Promise<Blob> {
    try {
      const remoteGateway = this.remoteGatewayFactory.createDefaultRemoteGateway();
      const file = await remoteGateway.download("/api/reportVoucherGenerated/lastExecuted");
      return Promise.resolve(file);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async downloadByModelReport(model: string): Promise<Blob> {
    try {
      const remoteGateway = this.remoteGatewayFactory.createDefaultRemoteGateway();
      const file = await remoteGateway.download(`/api/reportVoucherGenerated/byModel?model=${model}`);
      return Promise.resolve(file);
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
