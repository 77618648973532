import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/filter';
import { Subject } from 'rxjs/Subject';
import { Alert, AlertType } from '../models/domains/alert/alert';

@Injectable({
    providedIn: 'root'
  })
export class AlertService {

    private subject = new Subject<Alert>();
    private keepAfterRouteChange = false;

    constructor(private router: Router) {
        // clear alert messages on route change unless 'keepAfterRouteChange' flag is true
        router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterRouteChange) {
                    // only keep for a single route change
                    this.keepAfterRouteChange = false;
                } else {
                    // clear alert messages
                    this.clear();
                }
            }
        });
    }

    // subscribe to alerts
    getAlert(alertId?: string): Observable<any> {
        return this.subject.asObservable().filter((x: Alert) => x && x.alertId === alertId);
    }

    // convenience methods
    success(message: string, closeOption: boolean = true, alertId?: string) {
        this.clear(alertId);
        this.alert(new Alert({ message, type: AlertType.Success, closeOption, alertId: alertId }));
    }

    error(message: string, closeOption: boolean = true, alertId?: string) {
        this.alert(new Alert({ message, type: AlertType.Error, closeOption, alertId: alertId }));
    }

    onlyOneError(message: string, closeOption: boolean = true, alertId?: string) {
        this.clear(alertId);
        this.alert(new Alert({ message, type: AlertType.Error, closeOption, alertId: alertId }));
    }

    info(message: string, closeOption: boolean = true, alertId?: string) {
        this.alert(new Alert({ message, type: AlertType.Info, closeOption, alertId: alertId }));
    }

    warn(message: string, closeOption: boolean = true, alertId?: string) {
        this.clear(alertId);
        this.alert(new Alert({ message, type: AlertType.Warning, closeOption, alertId: alertId }));
    }

    alert(alert: Alert) {
        this.keepAfterRouteChange = alert.keepAfterRouteChange;
        this.subject.next(alert);
    }

    clear(alertId?: string) {
        this.subject.next(new Alert({ alertId }));
    }
}