import { Injectable } from '@angular/core';
import { OverlayRef } from '@angular/cdk/overlay';

@Injectable()
export class LoadingOverlayRef {
    private overlayRef: OverlayRef;

    constructor(overlayRef: OverlayRef){
        this.overlayRef = overlayRef;
    }

    closeLoading() {
        this.overlayRef.detach();
    }
}