import { ModuleWithProviders, NgModule } from '@angular/core';
import { HealthParser } from './health.parser';
import { MailVoucherParser } from './mail-voucher.parser';
import { NewsParser } from './news.parser';
import { PlaceholderImageParser } from './placeholder-image.parser';
import { PowerBiReportParser } from './powerbi-report-parser';
import { ScsAuthorizationTokenParser } from './scs-authorizatio-token.parser';
import { UserCredentialsActivationParser } from './user-credentials-activation.parser';
import { UserCredentialsParser } from './user-credentials.parser';
import { VoucherNotificationParser } from './voucher-notification.parser';
import { ErrorParser } from './error.parser';

@NgModule({})
export class ParserModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: ParserModule,
      providers: [
        ScsAuthorizationTokenParser,
        UserCredentialsParser,
        HealthParser,
        UserCredentialsActivationParser,
        PowerBiReportParser,
        NewsParser,
        PlaceholderImageParser,
        MailVoucherParser,
        VoucherNotificationParser,
        ErrorParser,
      ]
    };
  }
}
