import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuardService } from '../services/guards/authentication-guard.service';


const routes: Routes = [
  {
    path: 'login',
    loadChildren: "../pages/login/login.module#LoginModule"
  },
  {
    path: 'home',
    canActivate: [AuthenticationGuardService],
    loadChildren: "../pages/home/home.module#HomeModule"
  },
  {
    path: 'voucher-generator',
    canActivate: [AuthenticationGuardService],
    loadChildren: "../pages/voucher-generator/voucher-generator.module#VoucherGeneratorModule"
  },
  {
    path: 'reset-password',
    loadChildren: "../pages/reset-password/resetPassword.module#ResetPasswordModule"
  },
  {
    path: 'health',
    loadChildren: "../pages/health/health.module#HealthModule"
  },
  {
    path: 'active-user',
    loadChildren: "../pages/active-user/active-user.module#ActiveUserModule"
  },
  {
    path: 'register-password',
    loadChildren: "../pages/register-password/register-password.module#RegisterPasswordModule"
  },
  {
    path: '**',
    redirectTo: 'voucher-generator',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    AuthenticationGuardService
  ],
})
export class AppRoutingModule { }
