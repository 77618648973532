import { Injectable } from '@angular/core';
import { PlaceholderImageDTO } from '../../models/domains/usuario-voucher/placeholder-image.dto';
import { VoucherCriation } from '../../services/voucher-generator.service';
import { AbstractParser } from './parser';

@Injectable({
  providedIn: 'root'
})
export class PlaceholderImageParser extends AbstractParser<PlaceholderImageDTO>{
  parse(payload: VoucherCriation): PlaceholderImageDTO {
    return {
      placeholderName: 'posicao-imagem',
      x: Math.floor(payload.banner.numerationPosition.x),
      y: Math.floor(payload.banner.numerationPosition.y),
      font: payload.banner.font,
      fontSize: payload.banner.fontSize
    };
  }
}
