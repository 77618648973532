import { Injectable } from '@angular/core';
import { MailVoucherDTO } from '../../models/domains/usuario-voucher/mail-voucher.dto';
import { VoucherCriation } from '../../services/voucher-generator.service';
import { AbstractParser } from './parser';

@Injectable()
export class MailVoucherParser extends AbstractParser<MailVoucherDTO>{
  parse(payload: VoucherCriation): MailVoucherDTO {
    return {
      bodyHtmlMessage: payload.email.body,
      from: payload.email.sender,
      subject: payload.email.subject,
      mailAttachmentNameInitialNumber: payload.email.fileInitialNumber,
      mailAttachmentNamePattern: `${payload.email.prefixFilename}%index%%extension%`,
      to: payload.users.usersEmails.map(x=> x.email)
    };
  }
}
