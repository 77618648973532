import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { NgModule } from '@angular/core';
import { MatInputModule } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { ContextModule } from './ui/contexts/context.module';
import { ProviderModule } from './ui/providers/providers.module';
import { AppRoutingModule } from './ui/routing/routing.module';
import { ServiceModule } from './ui/services/service.module';

registerLocaleData(localePt);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    CoreModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ContextModule.forRoot(),
    ServiceModule.forRoot(),
    ProviderModule.forRoot()
  ],
  schemas: [
    MatInputModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
