import { Injectable } from '@angular/core';
import { AbstractParser } from './parser';
import { ReleaseNoteItem } from '../../models/domains/news/release-note-item.model';
import { ReleaseNoteType } from '../../models/domains/news/release-note-type.model';
import { ReleaseNote } from '../../models/domains/news/release-note.model';
import { release } from 'os';

@Injectable()
export class NewsParser extends AbstractParser<ReleaseNote>{
    parse(payload: any): ReleaseNote {
        let news: Array<ReleaseNoteItem> = [];
        let releaseNote: ReleaseNote = null;
        let items = payload['Items'];
        if (items) {
            items.forEach(item => {
                let releaseNoteItem: ReleaseNoteItem =
                    new ReleaseNoteItem(item['Title'], item['Description'], item['Type']);
                news.push(releaseNoteItem);
            });
            releaseNote = new ReleaseNote(payload['Name'], news);
        }

        return releaseNote;
    }
}