import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'svgFallback', pure: false })
export class SVGFallback implements PipeTransform {
    constructor() {
    }

    transform(content: string) {
        const isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
        if (isIEOrEdge) {
            return content.replace('svg', 'png');
        }
        else {
            return content;
        }
    }
}