export class FileUtils {
  static getFileInfo(file: File) {
    return new Promise<{ width: any; height: any }>((resolve, reject) => {
      const img = new Image();
      img.onload = function () {
        const imagem: any = this;
        resolve({
          height: imagem.height,
          width: imagem.width,
        });
      };
      img.onerror = function () {
        reject(this);
      };
      img.src = URL.createObjectURL(file);
    });
  }

  static readFileAsBinary(file: File): Promise<any[]> {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = (e) => {
        const arrayBuffer = fileReader.result;
        const data = new Uint8Array(arrayBuffer as ArrayBuffer);
        const arr = new Array();
        for (let i = 0; i !== data.length; ++i) {
          arr[i] = String.fromCharCode(data[i]);
        }
        resolve(arr);
      }
    });
  }

  static downloadBlob(blob: object, filename: string) {
    var a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';

    let url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = filename;
    a.click();

    window.URL.revokeObjectURL(url);
  };
}
