import { ReleaseNoteType } from './release-note-type.model';

export class ReleaseNoteEntity {
    name: string;
    items: Array<ReleaseNoteItemEntity>;
}

export class ReleaseNoteItemEntity {
    title: string;
    description: string;
    releaseNoteType: ReleaseNoteType;
}