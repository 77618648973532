import { Injectable } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material';
import { ModalComponent } from '../components/modal/modal.component';
import { EventEmitter } from 'events';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private modals: any[] = [];
  private dialog: MatDialog;
  private modalComponentDialogRef: MatDialogRef<ModalComponent>;

  add(modal: any): void {
    this.modals.push(modal);
  }

  remove(id: string): void {
    this.modals = this.modals.filter(x => x.modalInfo.ModalId !== id);
  }

  open(options: any): void {
    this.modalComponentDialogRef = this.dialog.open(ModalComponent, options);
  }

  getModalComponentDialogRef(): MatDialogRef<ModalComponent> {
    return this.modalComponentDialogRef;
  }

  setDialog(dialog: MatDialog): void {
    this.dialog = dialog;
  }

  setModalComponenteDialogRef(modalComponentDialogRef: MatDialogRef<ModalComponent>): void {
    this.modalComponentDialogRef = modalComponentDialogRef;
  }

  close(): void {
    this.modalComponentDialogRef.close();
    this.modalComponentDialogRef = null;
  }
}

