import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from "rxjs";
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { RoutePath } from '../models/domains/route-paths.model';
import { AuthService } from '../services/auth.service';
import { RouteStrategyFactory } from './route-strategy-factory';

@Injectable()
export class GlobalHttpExceptionHandller implements HttpInterceptor {
    constructor(
        private router: Router,
        private authService: AuthService,
        private routeStrategyFactory :RouteStrategyFactory) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((error) => {
                if (error instanceof HttpErrorResponse) {
                    this.doHandleUnauthorized(error);
                }
                return throwError(error);
            }));
    }

    private doHandleUnauthorized(error: any) {
        if (error.status === 401) {
            this.authService.logout();
            this.routeStrategyFactory.createStrategy().redirect();
        }
    }
}