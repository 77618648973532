import { ReleaseNoteItem } from '../models/domains/news/release-note-item.model';
import { RemoteGatewayFactory } from '../gateways/remote-gateway-factory';
import { NewsParser } from './parsers/news.parser';
import { BackendUrl } from '../models/domains/urls/back-end.url';
import { ReleaseNote } from '../models/domains/news/release-note.model';
import { Injectable } from '@angular/core';

@Injectable()
export class NewsRepository {

    constructor(
        private remoteGatewayFactory: RemoteGatewayFactory, 
        private newsParser: NewsParser) { }
        
    public async getNews(): Promise<ReleaseNote> {
        try {
            const headers = {
                'Content-Type': 'application/json'
            };
            const remoteGateway = this.remoteGatewayFactory.createDefaultRemoteGateway();
            const data = await remoteGateway.getWithHeaders(new BackendUrl('/api/releasenotes'), headers);
            return this.newsParser.parse(data);
        }
        catch (error) {
            return await Promise.reject(error);
        }
    }
}