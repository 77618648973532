import { Injectable } from '@angular/core';
import { UserVoucher } from '../models/domains/usuario-voucher/user-voucher';
import { MailVoucherParser } from '../repositories/parsers/mail-voucher.parser';
import { PlaceholderImageParser } from '../repositories/parsers/placeholder-image.parser';
import { VoucherNotificationParser } from '../repositories/parsers/voucher-notification.parser';
import { VoucherGeneratorRepository } from '../repositories/voucher-generator.repository';
import { FileUtils } from '../utils/file-utils';

export type VoucherCriation = {
  email: {
    subject: string;
    sender: string;
    body: string;
    prefixFilename: string;
    fileInitialNumber: number;
  };
  maxVouchersByUser: number;
  users: {
    file: File;
    usersEmails:  UserVoucher[];
  };
  numerations: File;
  banner: {
    campaignName: string;
    file: File;
    numerationPosition: { x: number, y: number };
    font: string;
    fontSize: number;
  }
}

@Injectable({
  providedIn: 'root'
})
export class VoucherGeneratorService {
  constructor(
    private voucherGeneratorRepository: VoucherGeneratorRepository,
    private voucherGeneratorParser: VoucherNotificationParser,
    private mailVoucherParser: MailVoucherParser,
    private placeholderImageParser: PlaceholderImageParser
  ) { }

  public async generateVouchers(configsVoucher: VoucherCriation): Promise<any> {
    try {
      const config = this.voucherGeneratorParser.parse(configsVoucher);
      return Promise.resolve(await this.voucherGeneratorRepository.generateVouchers(
        config,
        configsVoucher.banner.file,
        configsVoucher.users.file,
        configsVoucher.numerations
      ));
    }
    catch (error) {
      return Promise.reject(error);
    }
  }

  public async previewVouchers(configsVoucher: VoucherCriation): Promise<any> {
    try {
      const config = this.mailVoucherParser.parse(configsVoucher);
      const placeholders = [this.placeholderImageParser.parse(configsVoucher)];
      return Promise.resolve(await this.voucherGeneratorRepository.previewVouchers(
        config,
        placeholders,
        configsVoucher.banner.file
      ));
    }
    catch (error) {
      return Promise.reject(error);
    }
  }

  public async downloadLastExecutedReport(): Promise<any> {
    try {
      const file = await this.voucherGeneratorRepository.downloadLastExecutedReport();
      FileUtils.downloadBlob(file, 'Relatório da última execução.xlsx');
      return Promise.resolve(file);
    }
    catch (error) {
      return Promise.reject(error);
    }
  }
}
