import { Injectable } from '@angular/core';
import { VoucherNotificationDTO } from '../../models/domains/usuario-voucher/voucher-notification.dto';
import { VoucherCriation } from '../../services/voucher-generator.service';
import { AbstractParser } from './parser';
import { PlaceholderImageParser } from './placeholder-image.parser';

@Injectable()
export class VoucherNotificationParser extends AbstractParser<VoucherNotificationDTO>{
  constructor(
    private placeholderImageParser: PlaceholderImageParser
  ) {
    super();
  }

  parse(payload: VoucherCriation): VoucherNotificationDTO {
    return {
      model: payload.banner.campaignName,
      maxDistributionVoucher: payload.maxVouchersByUser,
      bodyHtmlMessage: payload.email.body,
      emailFrom: payload.email.sender,
      emailSubject: payload.email.subject,
      fileInitialNumber : payload.email.fileInitialNumber,
      nameEmailPattern: `${payload.email.prefixFilename}%index%%extension%`,
      emailTo: payload.users.usersEmails,
      placeholders: [this.placeholderImageParser.parse(payload)]
    };
  }
}
