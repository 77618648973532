export enum RoutePath {
    Default = '',
    Login = 'login',
    NotAuthorized = 'not-authorized',
    Home = 'voucher-generator',
    Logs = 'logs',
    HealthCheck = 'health',
    ActiveUser = 'active-user',
    RegisterPassword = 'register-password'
}
