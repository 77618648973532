import { ReleaseNoteType } from './release-note-type.model';

export class ReleaseNoteItem {
    private title: string;
    private description: string;
    private releaseNoteType: ReleaseNoteType;
    constructor(title: string, description: string, releaseNoteType: ReleaseNoteType) {
        this.title = title;
        this.description = description;
        this.releaseNoteType = releaseNoteType
    }
 
    getTitle(): string {
        return this.title;
    }

    getType(): ReleaseNoteType {
        return this.releaseNoteType;
    }


    getDescription(): string {
        return this.description;
    }
}