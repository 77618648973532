import { ModuleWithProviders, NgModule } from '@angular/core';
import { RepositoryModule } from '../repositories/repository.module';
import { AlertService } from './alert.service';
import { AuthService } from './auth.service';
import { DialogService } from './dialog.service';
import { GridService } from './grid.service';
import { DefaultAuthProcessorFactory } from './guards/processors/auth-processor-factory';
import { LoadingService } from './loading.service';
import { LocalStorageService } from './localStorage.service';
import { ModalService } from './modal.service';
import { NewsService } from './news.service';
import { OverlayService } from './overlay.service';
import { ToastService } from './toast.service';
import { UserService } from './user.service';
import { VoucherGeneratorService } from './voucher-generator.service';

@NgModule({
  imports: [RepositoryModule.forRoot()],
})

export class ServiceModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: ServiceModule,
      providers: [
        AlertService,
        AuthService,
        GridService,
        LoadingService,
        ModalService,
        UserService,
        DefaultAuthProcessorFactory,
        OverlayService,
        DialogService,
        ToastService,
        NewsService,
        LocalStorageService,
        VoucherGeneratorService
      ]
    };
  }
}

