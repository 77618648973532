import { Injectable } from "@angular/core";
import { AppContext } from "../contexts/app-context";
import { UserCredentials } from "../models/domains/credentials/user-credentials.model";
import { AuthRepository } from "../repositories/auth.repository";

@Injectable()
export class AuthService {

    constructor(
        private authRepository: AuthRepository,
        private appContext: AppContext
    ) { }

    public async authenticate(username: string, password: string): Promise<UserCredentials> {
        try {
            const userCredentials = await this.authRepository.login(username, password);
            this.appContext.setUserCredential(userCredentials);
            return Promise.resolve(userCredentials);
        }
        catch (error) {
            return await Promise.reject(error);
        }
    }

    logout(): void {
        this.appContext.clearUserCredential();
        this.appContext.clearServiceCredential();
    }

    async isAuthenticated(): Promise<boolean> {
        const credential = this.appContext.getUserCredential();
        if (credential && credential.token) {
            return await this.authRepository.validateUserToken(credential.token);
        }
        return false;
    }

    async isServiceTokenvValid(token: string): Promise<boolean> {
        if (token) {
            return await this.authRepository.validateServiceToken(token);
        }
        return false;
    }

    public isUserLogged(): boolean {
        const userCredentials = this.appContext.getUserCredential();
        return !!userCredentials && !!userCredentials.token;
    }

    public isServiceLogged(): boolean {
        const serviceCredentials = this.appContext.getServiceCredential();
        return !!serviceCredentials && !!serviceCredentials.token;
    }
    public async switchContext(switchContextHash: string): Promise<UserCredentials> {
        try {
            const hash = JSON.parse(atob(switchContextHash));
            const userCredentials = await this.authRepository.switchContext(hash.UserId, hash.RefreshToken, hash.SourceApplicationId);
            this.appContext.setUserCredential(userCredentials);
            return Promise.resolve(userCredentials);
        }
        catch (error) {
            return await Promise.reject(error);
        }
    }
}
