import { HealthStatus } from './healthStatus';
import { HealthService } from 'src/app/ui/services/health.service';
import { IGridColumnDisplayable } from '../grids/displayable-grid-column';

export class HealthItem implements IGridColumnDisplayable {
    
    constructor() {}
    
    private name: string;
    private description: string;
    private status: HealthStatus;
    private error: string;
 
    getName():string {
        return this.name;
    }

    getDescription():string {
        return this.description;
    }

    getStatus():HealthStatus {
        return this.status;
    }

    getError(): string {
       return this.error;
    }

    setName(name: string): void {
        this.name = name;
    }

    setDescription(description: string): void {
        this.description = description;
    }

    setStatus(status: HealthStatus): void {
        this.status = status;
    }

    setError(error: string): void {
        this.error = error;
    }

    canDisplay(): boolean {
        return this.getStatus() === HealthStatus.Unhealthy;
    }
}