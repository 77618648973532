import { Component, Input, ViewChild, TemplateRef, ViewContainerRef, ElementRef, DoCheck } from "@angular/core";
import { OverlayRef } from '@angular/cdk/overlay';
import { AppOverlayConfig, OverlayService } from '../../services/overlay.service';
import { ProgressSpinnerMode } from '@angular/material';

@Component({
    selector: "loading-panel",
    templateUrl: "./loading-panel.component.html",
    styleUrls: ["./loading-panel.component.scss"]
})
export class LoadingPanelComponent implements DoCheck {
    @ViewChild('loadingPanelRef', { static: true })
    private progressSpinnerRef: TemplateRef<any>;
    private progressSpinnerOverlayConfig: AppOverlayConfig;
    private overlayRef: OverlayRef;

    @Input() diameter?: number = 70;
    @Input() mode?: ProgressSpinnerMode = 'indeterminate';
    @Input() strokeWidth?: number = 0;
    @Input() backdropEnabled = false;
    @Input() positionGloballyCenter = true;
    @Input() displayLoading: boolean;
    @Input() text: string;
    @Input() elementRef?: ElementRef;
    @Input() spinner: boolean = true;

    constructor(private vcRef: ViewContainerRef, 
                private overlayService: OverlayService) { }
    ngOnInit() {
        this.configProgressSpinner();
        this.createOverlay();                
    }
 
    configProgressSpinner() :void {
        this.progressSpinnerOverlayConfig = {
            hasBackdrop: this.backdropEnabled
        };   
        if (this.positionGloballyCenter) {
            this.progressSpinnerOverlayConfig['positionStrategy'] = this.overlayService.positionGloballyCenter();
        }
        else {
            this.progressSpinnerOverlayConfig['positionStrategy'] = this.overlayService.getPosition(this.elementRef)
        }
    }

    createOverlay() :void {
        this.overlayRef = this.overlayService.createOverlay(this.progressSpinnerOverlayConfig);
    }

    openLoading(value: string) {
        this.displayLoading = true;
        this.text = value;
        this.spinner = false;
    }

    ngDoCheck() {
        if (this.displayLoading && !this.overlayRef.hasAttached()) {
            this.overlayService.attachTemplatePortal(this.overlayRef, this.progressSpinnerRef, this.vcRef);
        } else if (!this.displayLoading && this.overlayRef.hasAttached()) {
            this.overlayRef.detach();
        }
    }
}
