import { Injectable } from '@angular/core';
import { PageableResult } from '../models/domains/paginator/pageable-result';
import { MatPaginator } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class GridService {
  private initialPage: number;
  private currenPage: number;
  private maxPageLinkNumber: number;
  private pageLinks: Array<number>;
  private paginator: MatPaginator;

  constructor() { }

  getInitialPage(): number {
    return this.initialPage;
  }

  getCurrenPage(): number {
    return this.currenPage;
  }

  setInitialPage(initialPage: number): void {
    this.initialPage = initialPage;
  }

  setCurrentPage(currentPage: number): void {
    this.currenPage = currentPage;
  }

  getMaxPageLinkNumber(): number {
    return this.maxPageLinkNumber;
  }

  setMaxPageLinkNumber(maxPageLinkNumber: number): void {
    this.maxPageLinkNumber = maxPageLinkNumber;
  }

  getPageLinks(): Array<number> {
    return this.pageLinks;
  }

  setPageLinks(pageLinks: Array<number>): void {
    this.pageLinks = pageLinks;
  }

  hasNextPage(pageableResult: PageableResult): boolean {
    return pageableResult.getTotalItems() > pageableResult.getEnd() && pageableResult.getTotalPages() > 5;
  }

  hasFirstPage(pageableResult: PageableResult): boolean {
    return pageableResult.getEnd() > pageableResult.getItemsPerPage();
  }

  hasPreviousPage(pageableResult: PageableResult): boolean {
    return pageableResult.getEnd() > pageableResult.getItemsPerPage();
  }

  hasLastPage(pageableResult: PageableResult): boolean {
    return pageableResult.getTotalItems() > pageableResult.getEnd() && pageableResult.getTotalPages() > 5;
  }

  calculatePageLinks(totalPages: number): void {
    this.pageLinks = [];
    for (let i = 0; i < totalPages; i++) {
      this.pageLinks.push(i + 1);
    }
  }

  getPaginator(): MatPaginator {
    return this.paginator;
  }

  calculatePageIndex(listPageableResult: PageableResult): number {
    return (listPageableResult.getEnd() / listPageableResult.getItemsPerPage()) - 1;
  }

  setPaginator(paginator: MatPaginator): void {
    this.paginator = paginator;
  }

  updatePaging(listPageableResult: PageableResult): void {
    let currentPage = Math.ceil(listPageableResult.getStart() / listPageableResult.getItemsPerPage());
    this.setCurrentPage(currentPage);
    let maxLinkNumber = currentPage + 4;
    let links = new Array<number>();
    for (var i: number = currentPage; i <= maxLinkNumber && i <= listPageableResult.getTotalPages(); i++) {
      links.push(i);
    }
    this.setPageLinks(links);
  }
}
