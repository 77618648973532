import { Injectable } from "@angular/core";
import { UserCredentials } from "../models/domains/credentials/user-credentials.model";
import { AbstractParser } from "../repositories/parsers/parser";
import { environment } from "../../../environments/environment";
import { ServiceCredentials } from '../models/domains/credentials/service-credentials.model';
import { ReleaseNote } from '../models/domains/news/release-note.model';
import { ReleaseNoteEntity, ReleaseNoteItemEntity } from '../models/domains/news/release-note-entity.model';
import { Local } from 'protractor/built/driverProviders';
import { ReleaseNoteItem } from '../models/domains/news/release-note-item.model';

const USER_CREDENTIAL_KEY = `user-credential-${environment.appName}`;
const SERVICE_CREDENTIAL_KEY = "service-credential";
const RELEASE_NOTE_KEY = "releaseNote";

@Injectable()
export class AppContext {
    private userCredential: StorageEntry<UserCredentials>;
    private serviceCredential: StorageEntry<ServiceCredentials>;
    private lastReleaseNoteStoreage: StorageEntry<ReleaseNoteEntity>;

    constructor() {
        this.userCredential = new LocalStorageEntry<UserCredentials>(
            USER_CREDENTIAL_KEY
        );
        this.serviceCredential = new LocalStorageEntry<ServiceCredentials>(
            SERVICE_CREDENTIAL_KEY
        );
        this.lastReleaseNoteStoreage = new LocalStorageEntry<ReleaseNoteEntity>(
            RELEASE_NOTE_KEY
        );
    }

    public getBackendUrl(): string {
        return environment.backendUrl;
    }

    public setLastReleaseNote(releaseNote: ReleaseNote): void {
        const entity = new ReleaseNoteEntity();
        entity.name = releaseNote.getName();
        entity.items = releaseNote.getItems().map(i => {
            let item = new ReleaseNoteItemEntity();
            item.description = i.getDescription();
            item.releaseNoteType = i.getType();
            item.title = i.getTitle();
            return item;
        });
        this.lastReleaseNoteStoreage.set(entity);
    }

    public getLastReleaseNote(): ReleaseNote | null {
        const entity = this.lastReleaseNoteStoreage.get();
        if (entity) {
            let items: Array<ReleaseNoteItem> = []
            if (entity.items) {
                entity.items.forEach(item => {
                    items.push(new ReleaseNoteItem(item.title, item.description, item.releaseNoteType));
                });
            }
            return new ReleaseNote(entity.name, items);
        }
        return null;
    }

    public setUserCredential(credential: UserCredentials) {
        this.userCredential.set(credential);
    }

    public setServiceCredential(credential: ServiceCredentials) {
        this.serviceCredential.set(credential);
    }

    public getServiceCredential(): ServiceCredentials | null {
        return this.serviceCredential.get();
    }

    public getUserCredential(): UserCredentials | null {
        return this.userCredential.get();
    }

    public clearUserCredential() {
        this.userCredential.remove();
    }

    public clearServiceCredential() {
        this.serviceCredential.remove();
    }
}

abstract class StorageEntry<T> {
    protected constructor(
        protected key: string,
        protected parser: AbstractParser<T> | any = null,
        private storage: Storage
    ) { }

    protected parse(entity): T | null {
        return this.parser.parse(entity);
    }

    get(): T | null {
        const entity = this.storage.getItem(this.key);
        if (entity) {
            const entityJson = JSON.parse(entity);
            if (this.parser) {
                return Array.isArray(entityJson)
                    ? this.parser.parseList(entityJson)
                    : this.parser.parse(entityJson);
            }
            return entityJson;
        }
        return null;
    }

    set(item: T) {
        this.storage.setItem(this.key, JSON.stringify(item));
    }

    remove() {
        this.storage.removeItem(this.key);
    }
}

class LocalStorageEntry<T> extends StorageEntry<T> {
    constructor(
        protected key: string,
        protected parser: AbstractParser<T> | any = null
    ) {
        super(key, parser, localStorage);
    }
}

class SessionStorageEntry<T> extends StorageEntry<T> {
    constructor(
        protected key: string,
        protected parser: AbstractParser<T> | any = null
    ) {
        super(key, parser, sessionStorage);
    }
}
