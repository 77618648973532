import { Injectable } from '@angular/core';

export interface IStorageService {
  getItem<T = any>(id: string): T | null;
  save<T = any>(id: string, data: T): void;
  remove(id: string): void;
}

@Injectable()
export class LocalStorageService implements IStorageService {
  save<T = any>(id: string, data: T): void {
    localStorage.setItem(`@voucher-generator/${id}`, JSON.stringify(data));
  }

  remove(id: string): void {
    localStorage.removeItem(`@voucher-generator/${id}`);
  }

  getItem<T = any>(id: string): T | null {
    const value = localStorage.getItem(`@voucher-generator/${id}`);
    if (!value) {
      return null;
    }
    return JSON.parse(value) as T;
  }
}
