export class Error {
    
    errorMessage: string;
    errorCode: string;

    getErrorMessage() :string {
        return this.errorMessage;
    }

    getErrorCode() :string {
        return this.errorCode;
    }

    setErrorMessage(errorMessage: string) :void {
        this.errorMessage = errorMessage;
    }

    setErrorCode(errorCode: string) :void {
        this.errorCode = errorCode;
    }
}