import { Injectable } from '@angular/core';
import { AbstractParser } from './parser';
import { Error } from '../../models/domains/error/error.model';

@Injectable()
export class ErrorParser extends AbstractParser<Error> {
    parse(payload: any): Error {
        const error = new Error();
        const { Data } = payload.error;
        if (Data === undefined) {
            error.setErrorMessage('Serviço fora do ar');
            error.setErrorCode('0');
        }
        else {
            error.setErrorMessage(payload.error.Data.ErrorMessage);
            error.setErrorCode(payload.error.Data.ErrorCode);
        }
        return error;
    }
}
