import { Injectable } from '@angular/core';
import { NewsRepository } from '../repositories/news.repository';
import { ReleaseNote } from '../models/domains/news/release-note.model';
import { AppContext } from '../contexts/app-context';
import { release } from 'os';
import { relative } from 'path';

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  constructor(
    private newsRepository: NewsRepository,
    private appContext: AppContext) { }

  isEqualsToLastReleaseNotes(releaseNote: ReleaseNote): boolean {
    let lastReleaseNote = this.appContext.getLastReleaseNote();
    return !!lastReleaseNote && !!releaseNote && lastReleaseNote.getName() === releaseNote.getName();
  }

  public getLastReleaseNote(): ReleaseNote {
    return this.appContext.getLastReleaseNote();
  }

  public async getNews(): Promise<ReleaseNote> {
    try {
      return Promise.resolve(await this.newsRepository.getNews());
    }
    catch (error) {
      return await Promise.reject(error);
    }
  }

  public markAsRead(releaseNote: ReleaseNote): void {
    this.appContext.setLastReleaseNote(releaseNote);
  }
}
